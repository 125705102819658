<template>
    <div class="news-component">
        <media-header />
        <div class="news-container faded-content-for-list-update">
            <news-item
                v-for="(item, index) in items"
                :item="item"
                :index="index"
                :key="index"
            />
        </div>
        <p v-if="items.length === 0" class="msg">News not Found</p>

        <paginate
            v-model="activePage"
            :page-count="pageCount"
            container-class="pagination"
            :page-range="15"
            :prev-text="prev"
            :next-text="next"
            :click-handler="paginationClickCallback"
        >
            <span slot="breakViewContent">
                <svg width="16" height="4" viewBox="0 0 16 4">
                    <circle fill="#999999" cx="2" cy="2" r="2" />
                    <circle fill="#999999" cx="8" cy="2" r="2" />
                    <circle fill="#999999" cx="14" cy="2" r="2" />
                </svg>
            </span>
        </paginate>
    </div>
</template>

<script>
import NewsItem from "@/components/PageComponents/NewsItem";
import MediaHeader from "@/components/custom/MediaHeader";
import Paginate from "vuejs-paginate";
import getDataList from "@/mixins/getDataList";

export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        },
        config: {
            type: Object,
            default: () => {}
        },
        name: {
            type: String,
            default: ""
        }
    },
    mixins: [getDataList],
    components: {
        NewsItem,
        MediaHeader,
        Paginate
    },
    computed: {
        items() {
            let data = this.pagingDataList || this.data;

            return data?.list?.map(item => ({
                header: item.title,
                date: item.date,
                path: item?.cover_image?.[0]?.devices?.[this.device?.type],
                id: item.id,
                slug: item?.slug
            }));
        }
    }
};
</script>

<style lang="scss" scoped>
.news-component /deep/ {
    $ease-out: cubic-bezier(0.15, 0.16, 0.37, 1);

    .news-container {
        position: relative;
        margin: -70px 225px 0;
        display: flex;
        flex-direction: column;
        @media only screen and (max-width: 1650px) and (min-width: 1440px) {
            margin: -70px 165px 0;
        }
        @media only screen and (max-width: 1439px) and (min-width: 1366px) {
            margin: -50px 128px 0;
        }
        @media only screen and (max-width: 1365px) and (min-width: 768px) {
            margin: -30px 47px 0;
        }
        @media only screen and (max-width: 769px) {
            margin: 0 16px 0;
        }
    }

    .news {
        margin-top: 268px;
        @media only screen and (max-width: 1439px) and (min-width: 1366px) {
            margin-top: 263px;
        }
        @media only screen and (max-width: 1365px) and (min-width: 768px) {
            margin-top: 203px;
        }
        @media only screen and (max-width: 767px) {
            margin-top: 55px;
        }

        &:first-child {
            margin-top: 0;
        }

        &.reverse {
            display: flex;
            justify-content: flex-end;
            @media only screen and (max-width: 767px) {
                flex-direction: column;
            }
        }
    }
    .msg {
        margin-top: 150px;
        font-size: 21px;
    }
    .pagination {
        display: flex;
        align-items: center;
        width: fit-content;
        margin: 144px auto 0;
        list-style-type: none;

        li {
            margin: 0 10px;
            font-weight: normal;
            font-size: 34px;
            text-align: left;
            color: #0a141c;
            transition: all 0.6s $ease-out;

            &.active {
                color: #93272c;
            }

            a {
                outline: none;
                -moz-user-select: none;
                -webkit-user-select: none;
                -ms-user-select: none;
                -webkit-tap-highlight-color: transparent;
            }

            .next {
                svg {
                    transform: rotate(180deg);
                }
            }
        }
    }
}
</style>
